import {
  AzureDevopsTicketContextType,
  useGetTicketContextQuery,
} from "~/operations";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash-es";

type UseAzureTicketContextParams = {
  scopeMrn: string;
  skip?: boolean;
};

export function useAzureTicketContext({
  scopeMrn,
  skip = false,
}: UseAzureTicketContextParams) {
  const { data: ticketData, refetch } = useGetTicketContextQuery({
    variables: {
      input: {
        scopeMrn,
        azureDevops: {
          type: AzureDevopsTicketContextType.Projects,
        },
      },
    },
    skip: skip,
  });

  const fetchProjects = useMemo(
    () =>
      debounce((query: string) => {
        refetch({
          input: {
            scopeMrn,
            azureDevops: {
              type: AzureDevopsTicketContextType.Projects,
              projects: { query },
            },
          },
        });
      }, 400),
    [],
  );

  const selectedProjectKey = "case.azure.projectId";

  const projects =
    ticketData?.ticketContext.__typename ===
    "AzureDevopsTicketContextProjectsPayload"
      ? ticketData?.ticketContext?.projects
      : // Important to keep it as undefined and not [], because it's used in hooks dependency array
        undefined;

  const isProjectsEmpty = (projects?.length || 0) === 0;

  const saveSelectedProject = (value: string) => {
    localStorage.setItem(selectedProjectKey, value);
  };

  const loadSelectedProject = useCallback(() => {
    const storedProjectId = localStorage.getItem(selectedProjectKey) || "";
    return (projects || []).find((project) => project.name === storedProjectId);
  }, [projects]);

  return {
    projects,
    isProjectsEmpty,
    saveSelectedProject,
    loadSelectedProject,
    fetchProjects,
  };
}
