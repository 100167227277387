import { Box, alpha, Tooltip, useTheme } from "@mui/material";
import { isNumber } from "lodash-es";
import { Flex } from "~/components/Flex";
import TextCaption from "../../typography/TextCaption";
import TextButton from "../../typography/TextButton";

import { getStateColor } from "../../../utils";
import {
  ArrowRightAltIcon,
  CheckIcon,
  CloseIcon,
  DataObjectIcon,
  RemoveIcon,
  ShieldFilledIcon,
  WarningAmberIcon,
} from "~/components/icons";

type ControlResultIconProps = {
  status: "pass" | "fail" | "skip" | "error" | "errorScan" | "remove" | string;
  count?: number;
  label?: string;
  colorOverride?: string;
  size?: "sm" | "md" | "lg";
  disableTooltip?: boolean;
};

export const ControlResultIcon = ({
  status,
  count,
  label,
  colorOverride,
  size = "md",
  disableTooltip = false,
}: ControlResultIconProps) => {
  const theme = useTheme();

  const getDiameter = (size: ControlResultIconProps["size"]) => {
    switch (size) {
      case "sm":
        return theme.spacing(2.4);
      case "lg":
        return theme.spacing(6);
      case "md":
      default:
        return theme.spacing(4);
    }
  };

  const getFontSize = (size: ControlResultIconProps["size"]) => {
    switch (size) {
      case "sm":
        return 14;
      case "lg":
        return 32;
      case "md":
      default:
        return 24;
    }
  };

  const diameter = getDiameter(size);
  const fontSize = getFontSize(size);

  const color = colorOverride || getStateColor(theme, status);

  const iconStyles = {
    fontSize: fontSize,
    lineHeight: "16px",
    color,
  };

  const getIcon = (status: string) => {
    switch (status?.toLowerCase()) {
      case "pass":
      case "passed":
        return <CheckIcon sx={iconStyles} />;
      case "fail":
      case "failed":
        return <CloseIcon sx={iconStyles} />;
      case "skip":
      case "skipped":
        return <ArrowRightAltIcon sx={iconStyles} />;
      case "errorscan":
      case "error":
      case "errors":
        return (
          <WarningAmberIcon
            sx={{
              ...iconStyles,
              fontSize: fontSize * 0.8,
              mt: -0.25,
            }}
          />
        );
      case "security":
        return (
          <ShieldFilledIcon
            sx={{ ...iconStyles, fontSize: fontSize * 0.8 }}
            fill={color}
          />
        );
      case "data":
        return <DataObjectIcon sx={iconStyles} />;
      case "remove":
        return <RemoveIcon sx={iconStyles} />;
      default:
        // unscored
        return <RemoveIcon sx={iconStyles} />;
    }
  };

  const toolTipContent = (status: string): string => {
    switch (status?.toLowerCase()) {
      case "pass":
      case "passed":
        return "Pass";
      case "fail":
      case "failed":
        return "Fail";
      case "skip":
      case "skipped":
        return "Skipped";
      case "error":
      case "errors":
      case "errorscan":
        return "Error";
      case "data":
        return "Data";
      case "remove":
        return "skipped";
      default:
        return "Disabled";
    }
  };

  return (
    <Flex center flexDirection="column">
      <Tooltip
        title={toolTipContent(status)}
        placement="top"
        arrow
        open={disableTooltip ? false : undefined}
      >
        <Flex center gap={1}>
          {label && <TextButton color={color}>{label}</TextButton>}
          <Box
            sx={[
              {
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: diameter,
                height: diameter,
                borderRadius: "50%",
                background: alpha(color, 0.1),
              },
              disableTooltip
                ? {}
                : {
                    transition: "box-shadow 300ms ease-in-out",
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: `0px 0px 0px 1px ${color}`,
                    },
                  },
            ]}
          >
            {getIcon(status)}
          </Box>
        </Flex>
      </Tooltip>
      {isNumber(count) && <TextCaption color={color}>{count}</TextCaption>}
    </Flex>
  );
};

ControlResultIcon.displayName = "ControlResultIcon";
