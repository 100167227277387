import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { TableCell, Typography, TypographyProps } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { useScope } from "~/hooks/useScope";

type TopSecurityFindingCellProps = {
  type: string;
  title: string;
  findingMrn?: string;
  assetId?: string;
  size?: string;
  description?: string;
  TypographyProps?: TypographyProps;
};

export function TopSecurityFindingCell({
  type,
  size,
  ...props
}: TopSecurityFindingCellProps) {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId") || "";
  const projectId = searchParams.get("projectId") || "";
  const { activeScope, spaceScope } = useScope();
  const isDashboardCard = size === "small";

  const checkTitle = props.title;
  const cveTitle = props?.description
    ? `${props.title}: ${props.description}`
    : props.title;

  const title = type === "CHECK" ? checkTitle : cveTitle;

  const generateHref = (): string | null => {
    const signal = props.findingMrn?.split("/").pop();
    const queryParams = new URLSearchParams({
      scopeId: activeScope?.id || "",
      spaceId: spaceScope?.id || "",
    });

    if (jobId && projectId) {
      queryParams.set("jobId", jobId);
      queryParams.set("projectId", projectId);
    }

    const commonUrlPart1 = `/space/inventory/${props.assetId}`;

    if (type === "CheckFinding") {
      return (
        commonUrlPart1 + `/check?checkMrn=${props.findingMrn}&` + queryParams
      );
    }

    if (type === "CveFinding") {
      return commonUrlPart1 + `/vulnerabilities/${signal}?` + queryParams;
    }

    if (type === "AdvisoryFinding") {
      return commonUrlPart1 + `/advisories/${signal}?` + queryParams;
    }
    return null;
  };

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography
          {...(props.findingMrn && {
            component: RouterLink,
            to: generateHref(),
          })}
          sx={{
            fontSize: isDashboardCard ? 12 : 14,
            fontWeight: isDashboardCard ? 400 : 700,
            lineHeight: "16px",
          }}
          {...props.TypographyProps}
        >
          {title}
        </Typography>
      </Clamp>
    </TableCell>
  );
}
