import { AggregateScoreType, ExceptionType } from "~/operations";
import {
  AdvisoryOnAssetFindingNode,
  CheckOnAssetFindingNode,
  CveOnAssetFindingNode,
  FindingNode,
} from "~/pages/findings/FindingOnAsset/types";
import { Stack, Typography } from "@mui/material";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import React from "react";
import { ExceptionTarget } from "~/components/exceptions/types";
import { MONDOO_VULN_MRN_PREFIX } from "../../../../constants";

type FindingUtilsCommonParams = {
  vulnId: string;
  advisoryId: string;
  checkMrn: string;
};

export function getFindingMrn({
  vulnId,
  advisoryId,
  checkMrn,
}: FindingUtilsCommonParams): string {
  if (checkMrn) {
    return checkMrn;
  }

  if (vulnId) {
    return `${MONDOO_VULN_MRN_PREFIX}/cves/${vulnId}`;
  }

  if (advisoryId) {
    return `${MONDOO_VULN_MRN_PREFIX}/advisories/${advisoryId}`;
  }

  return "";
}

export function getFindingAggregateScoreType({
  vulnId,
  advisoryId,
  checkMrn,
}: FindingUtilsCommonParams): AggregateScoreType | null {
  if (checkMrn) return AggregateScoreType.Check;

  if (vulnId) return AggregateScoreType.Vulnerability;

  if (advisoryId) return AggregateScoreType.Advisory;

  return null;
}

export function getFindingExceptionTypes({
  vulnId,
  advisoryId,
  checkMrn,
}: FindingUtilsCommonParams): ExceptionType[] {
  if (checkMrn) return [ExceptionType.Security];

  if (vulnId) return [ExceptionType.Cve];

  if (advisoryId) return [ExceptionType.Advisory];

  return [];
}

type GetFindingExceptionMrnsReturn = {
  advisoryMrns?: Array<string>;
  cveMrns?: Array<string>;
  queryMrns?: Array<string>;
};

export function getFindingExceptionMrns({
  vulnId,
  advisoryId,
  checkMrn,
}: FindingUtilsCommonParams): GetFindingExceptionMrnsReturn {
  return {
    ...(advisoryId
      ? {
          advisoryMrns: [getFindingMrn({ vulnId, advisoryId, checkMrn })],
          applyToCves: true,
        }
      : {}),
    ...(vulnId
      ? { cveMrns: [getFindingMrn({ vulnId, advisoryId, checkMrn })] }
      : {}),
    ...(checkMrn
      ? { queryMrns: [getFindingMrn({ vulnId, advisoryId, checkMrn })] }
      : {}),
  };
}

type GetFindingLinkBackParams = FindingUtilsCommonParams & {
  assetId: string;
  jobId: string | undefined;
  projectId: string | undefined;
  scopeParams: URLSearchParams;
};

export function getFindingLinkBack({
  vulnId,
  advisoryId,
  checkMrn,
  assetId,
  jobId,
  projectId,
  scopeParams,
}: GetFindingLinkBackParams): string {
  if (vulnId) {
    return jobId && projectId
      ? `/space/cicd/jobs/${projectId}/vulnerabilities?${scopeParams}&projectId=${projectId}&jobId=${jobId}`
      : `/space/inventory/${assetId}/vulnerabilities?${scopeParams}`;
  }

  if (advisoryId) {
    return jobId && projectId
      ? `/space/cicd/jobs/${projectId}/advisories?${scopeParams}&projectId=${projectId}&jobId=${jobId}`
      : `/space/inventory/${assetId}/advisories?${scopeParams}`;
  }

  if (checkMrn) {
    return jobId && projectId
      ? `/space/cicd/jobs/${projectId}/checks?${scopeParams}&projectId=${projectId}&jobId=${jobId}`
      : `/space/inventory/${assetId}/checks?${scopeParams}`;
  }

  return jobId && projectId
    ? `/space/cicd/jobs/${projectId}/overview?${scopeParams}&projectId=${projectId}&jobId=${jobId}`
    : `/space/inventory/${assetId}/`;
}

type FindingEntityCommonParams = {
  finding: FindingNode | undefined;
};

export function getFindingTitle({
  finding,
}: FindingEntityCommonParams): string {
  if (isAdvisoryOrVulnerabilityFinding(finding))
    return `${finding?.id} on ${finding?.asset?.name}`;

  if (isCheckFinding(finding))
    return `${finding?.title} on ${finding?.asset?.name}`;

  return "";
}

export function getFindingSubheaders({ finding }: FindingEntityCommonParams) {
  if (finding?.__typename === "PackageFinding") return [];

  const commonSubheader = {
    id: "platform-title",
    label: "Platform title",
    value: finding?.asset?.platform?.title,
    component: (
      <Stack direction="row" spacing={1}>
        <DynamicIcon iconId={finding?.asset?.icon} />
        <Typography className="info-asset-title">
          {finding?.asset?.platform?.title}
        </Typography>
      </Stack>
    ),
  };

  if (isAdvisoryOrVulnerabilityFinding(finding)) {
    return [
      commonSubheader,
      {
        id: "first-found",
        label: "First found",
        value: finding?.firstDetectedAt,
      },
      {
        id: "last-confirmed",
        label: "Last confirmed",
        value: finding?.lastUpdated,
      },
    ];
  }

  if (isCheckFinding(finding)) {
    return [
      commonSubheader,
      {
        id: "last-updated-at",
        label: "Last updated at",
        value: finding?.lastUpdated,
      },
    ];
  }

  return [];
}

export function isAdvisoryFinding(
  finding: FindingEntityCommonParams["finding"] | null | undefined,
): finding is AdvisoryOnAssetFindingNode {
  return finding?.__typename === "AdvisoryFinding";
}

export function isVulnerabilityFinding(
  finding: FindingEntityCommonParams["finding"] | null | undefined,
): finding is CveOnAssetFindingNode {
  return finding?.__typename === "CveFinding";
}

export function isAdvisoryOrVulnerabilityFinding(
  finding: FindingEntityCommonParams["finding"] | undefined,
): finding is AdvisoryOnAssetFindingNode | CveOnAssetFindingNode {
  return isAdvisoryFinding(finding) || isVulnerabilityFinding(finding);
}

export function isCheckFinding(
  finding: FindingEntityCommonParams["finding"] | null | undefined,
): finding is CheckOnAssetFindingNode {
  return finding?.__typename === "CheckFinding";
}

export function getCheckFindingRemediation({
  finding,
  checkData,
}: FindingEntityCommonParams & {
  checkData: CheckOnAssetFindingNode["mquery"] | undefined;
}) {
  if (isCheckFinding(finding)) {
    const remediations = checkData?.docs?.remediations;
    const hasRemediations =
      remediations?.__typename === "Remediations" &&
      remediations.entries.length > 0;

    return hasRemediations ? remediations : { entries: [] };
  }

  return { entries: [] };
}

export function getFindingExceptionTarget(
  finding: FindingEntityCommonParams["finding"] | undefined,
): ExceptionTarget {
  if (isCheckFinding(finding)) return "check";

  if (isVulnerabilityFinding(finding)) return "cve";

  if (isAdvisoryFinding(finding)) return "advisory";

  return "finding";
}
