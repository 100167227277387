import { ReactNode, useEffect, useRef, useState } from "react";
import { debounce } from "lodash-es";
import * as d3 from "d3";
import { alpha, Box, Grid, styled, Typography, useTheme } from "@mui/material";
import {
  DonutChartTooltip,
  generateTooltipContent,
} from "./donut-chart-tooltip";

type Props = {
  data: ChartData[];
  dataType: string;
  small?: boolean;
  sectionWidth?: [number, number];
  chartId?: string; // give the chart a unique ID
  chartMaxWidth?: number; // set a max width for the chart in pixel number
  onSectionClick?: (section: ChartData) => void;
  selectedSections?: string[];
};

export type ChartData = {
  label: string;
  value: number;
  color: string;
};

export function DonutChart({
  data,
  dataType,
  sectionWidth = [5.3, 6.7],
  small = false,
  chartId,
  chartMaxWidth = 370,
  onSectionClick,
  selectedSections,
}: Props) {
  const theme = useTheme();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<ReactNode>(<div></div>);
  const chartRef = useRef<SVGSVGElement | null>(null);
  const emptyRef = useRef<SVGSVGElement | null>(null);
  const legendRef = useRef<SVGSVGElement | null>(null);
  const legendGridRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLElement | null>(null);
  const chartSizingRef = useRef<HTMLElement | null>(null);

  // get the total of all the values
  const total = data.reduce((acc: number, obj: ChartData): number => {
    return acc + obj.value;
  }, 0);

  // is the data list coming in empty, if so we'll show an empty chart.
  const isEmpty = Boolean(total < 1);

  // when the small flag is applied, the chart gets a little thicker ring
  const innerRadiusMultiplier = small ? 0.7 : 0.8;

  // Variable Setup
  const width = 100;
  const height = 100;
  const min = Math.min(width, height);
  const oRadius = (min / 2) * 1; // Lowering the outer radius will give the chart some padding
  const iRadius = (min / 2) * innerRadiusMultiplier; // Inner Radius => the donut hole

  // Anytime the windowsize changes, we need to replot our chart and tooltips
  // based on the new viewbox.
  const handleRedraw = (): void => {
    const chart = drawChart();
    const legend = createLegend();
    generateHoverEvents(chart, legend);
  };

  // build the default pie layout
  const pie = d3
    .pie<ChartData>()
    .value((d) => d.value) // using the value from our demoData objects
    .sort(null)
    .padAngle(0.015);

  // create the arc generator => this guides the shapes of each section
  const arc = d3
    .arc<d3.PieArcDatum<ChartData>>()
    .innerRadius(iRadius)
    .outerRadius(oRadius)
    .cornerRadius(1);

  // Arc generator used for the hovered/expanded arc sections
  const arcOver = d3
    .arc<d3.PieArcDatum<ChartData>>()
    .innerRadius(iRadius - 5)
    .outerRadius(oRadius)
    .cornerRadius(1);

  // Height of each legend label
  const labelHeight: number = 7;

  // The number of data points in the incoming data array
  const dataSize = Object.keys(data).length;

  useEffect(() => {
    const chart = drawChart();
    const legend = createLegend();
    generateHoverEvents(chart, legend);

    window.addEventListener("resize", debounce(handleRedraw, 50));

    return () => {
      chart.remove();
      legend.remove();
    };
  }, []);

  useEffect(() => {
    handleRedraw();
  }, [selectedSections]);

  // draw the donut chart
  const drawChart = () => {
    // if there's no data to show, we'll draw a generic empty chart
    if (isEmpty) {
      return drawEmpty();
    }

    const chart = d3
      .select<SVGSVGElement | null, d3.PieArcDatum<ChartData>>(chartRef.current)
      .attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`);

    // Filter out any data that less than 0.
    // 0 values don't show up on the chart, but if we leave them in, padding
    // will be applied to that section causing the chart to look 'off'
    const filteredData = data.filter((d) => d.value > 0);
    let g = chart.datum(filteredData).selectAll("path").data(pie);

    g.exit().remove();

    g.enter()
      .append("path")
      .attr("class", "piechart")
      .attr("fill", (d) => d.data.color)
      .on("click", (e, d) => {
        e.preventDefault();
        onSectionClick && onSectionClick(d.data);
      })
      .attr("d", arc);

    if (selectedSections) {
      // loop through all the sections with the class pie chart and expand the selected sections
      chart
        .selectAll<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
          ".piechart",
        )
        .filter((d) => selectedSections.includes(d.data.label))
        .classed("pointer", true)
        .transition()
        .duration(250)
        .attr("d", arcOver);

      // loop through all the sections with the class pie chart and draw the unselected sections
      chart
        .selectAll<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
          ".piechart",
        )
        .filter((d) => !selectedSections.includes(d.data.label))
        .classed("pointer", true)
        .transition()
        .duration(250)
        .attr("d", arc);
    }

    return chart;
  };

  const drawEmpty = () => {
    const emptyChart = d3
      .select<SVGSVGElement | null, d3.PieArcDatum<ChartData>>(emptyRef.current)
      .attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`);
    const emptyData = [
      { label: "", value: 1, color: alpha(theme.palette.text.primary, 0.3) },
    ];

    let g = emptyChart.datum(emptyData).selectAll("path").data(pie);

    g.exit().remove();

    g.enter()
      .append("path")
      .attr("class", "empty-piechart")
      .attr("fill", (d) => d.data.color)
      .attr("d", arc);

    return emptyChart;
  };

  // draw the legend which is displayed to the right of the donut chart
  const createLegend = () => {
    const legendGridWidth = legendGridRef.current?.offsetWidth;
    const legend = d3
      .select(legendRef.current)
      .attr(
        "viewBox",
        `0 0 ${legendGridWidth} ${dataSize * (labelHeight * 3)}`,
      );
    // provide the legend the data it needs to consume
    let g = legend.datum(data).selectAll(".list-item").data(pie);

    g.exit().remove();

    g.enter()
      .append("g")
      .attr("class", "list-item")
      .classed("desaturate", (d) => d.data.value < 1)
      .attr(
        "transform",
        (d) => `translate(12, ${14 * d.index * 1.5 + labelHeight + 4})`,
      )
      .call((parent) => {
        // call lets us build a nested element which here contains
        // a circle, and text
        parent
          .append("circle")
          .attr("r", `${labelHeight}`)
          .attr("fill", (d) => d.data.color);
        parent
          .append("text")
          .text((d) => d.data.label)
          .attr("fill", `${theme.palette.text.primary}`)
          .attr("transform", "translate(12,5)")
          .style("font-size", "12px")
          .style("font-weight", "700")
          .style("text-transform", "uppercase")
          .call((parent) => {
            parent
              .append("tspan")
              .text((d) => d.data.value)
              .attr("dx", 4)
              .style("font-weight", "400");
          });
      });

    return legend;
  };

  const generateHoverEvents = (
    chart: d3.Selection<
      SVGSVGElement | null,
      d3.PieArcDatum<ChartData>,
      null,
      undefined
    >,
    legend: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  ) => {
    const tooltip = d3.select(tooltipRef.current);
    const currentWidth = chartSizingRef.current?.offsetWidth || 100;
    const tooltipRadius = (currentWidth / 2) * 0.85;

    // The tooltips need their own arc so that they land in the middle of the pie slices
    const tooltipArc = d3
      .arc<d3.PieArcDatum<ChartData>>()
      .innerRadius(tooltipRadius)
      .outerRadius(tooltipRadius);

    // this function consolidates everything needed to draw the tooltips
    const applyTooltips = (d: d3.PieArcDatum<ChartData>) => {
      // determine what content will go inside the tooltip
      const content = generateTooltipContent(d.data, total);
      // get the x and y coordinates of where the tooltip should be positioned.
      const x = tooltipArc.centroid(d)[0] + currentWidth / 2;
      const y = tooltipArc.centroid(d)[1] + currentWidth / 2;
      // translate the tooltip to its marker, subtracting 6px vertically for the point of the tooltip
      tooltip.style(
        "transform",
        `translate(calc(-50% + ${x}px), calc(-100% + ${y - 6}px))`,
      );
      // apply the tooltip content
      setTooltipContent(content);
      // display the tooltip
      setShowTooltip(true);
    };

    if (!onSectionClick) {
      // Apply mouse events to the piechart
      chart
        .selectAll<
          SVGPathElement | null,
          d3.PieArcDatum<ChartData>
        >(".piechart")
        .on("mouseenter", (event, d) => {
          // redraw the hovered section with the larger section by
          // adjusting its 'd' attribute to the larger arc generator.
          d3.select<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
            event.target,
          )
            .classed("active", true)
            .transition()
            .duration(250)
            .attr("d", arcOver);

          applyTooltips(d);
        });

      // Reverse the above action when the mouse leaves the arc segment.
      chart
        .selectAll<
          SVGPathElement | null,
          d3.PieArcDatum<ChartData>
        >(".piechart")
        .on("mouseleave", (event) => {
          d3.select<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
            event.target,
          )
            .classed("active", false)
            .transition()
            .duration(250)
            .attr("d", arc);

          setShowTooltip(false);
        });
    }

    legend
      .selectAll<SVGGElement | null, d3.PieArcDatum<ChartData>>(".list-item")
      .on("mouseenter", (_event, d) => {
        // redraw the piechart with the extend arc generator
        chart
          .selectAll<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
            `.piechart:nth-child(${d.index + 1})`,
          )
          .transition()
          .duration(250)
          .attr("d", arcOver);

        applyTooltips(d);
      });

    legend
      .selectAll<SVGGElement | null, d3.PieArcDatum<ChartData>>(".list-item")
      .on("mouseleave", (_event, d) => {
        // reverse the above when we move outside of one of the legend items
        chart
          .selectAll<SVGPathElement | null, d3.PieArcDatum<ChartData>>(
            `.piechart:nth-child(${d.index + 1})`,
          )
          .transition()
          .duration(250)
          .attr("d", arc);

        setShowTooltip(false);
      });
  };

  return (
    <Grid
      {...(chartId ? { id: chartId } : {})}
      container
      justifyContent="center"
      alignItems="center"
      p={2}
      maxWidth={chartMaxWidth}
    >
      <Grid item xs={sectionWidth[0]} sx={{ position: "relative" }}>
        <DonutChartTooltip
          ref={tooltipRef}
          {...{ tooltipContent, showTooltip }}
        />

        {/* The text needs to be done as an overlay so that 
        its size isn't elevated as a result of svg scaling */}
        <Box
          width={1}
          height={1}
          ref={chartSizingRef}
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            pointerEvents: "none",
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={12}
            sx={{ textTransform: "uppercase", opacity: isEmpty ? 0.3 : 1 }}
            hidden={Boolean(small)}
            className="chart-dataType"
          >
            {dataType}
          </Typography>
          <Typography
            fontSize={12}
            sx={{ textTransform: "uppercase", opacity: isEmpty ? 0.3 : 1 }}
          >
            {total}
          </Typography>
        </Box>
        {isEmpty ? (
          <ChartSvg ref={emptyRef} preserveAspectRatio="xMinYMin"></ChartSvg>
        ) : (
          <ChartSvg ref={chartRef} preserveAspectRatio="xMinYMin"></ChartSvg>
        )}
      </Grid>
      <Grid item xs={sectionWidth[1]} pl={small ? 2 : 3} ref={legendGridRef}>
        <LegendSvg ref={legendRef} preserveAspectRatio="xMinYMin" />
      </Grid>
    </Grid>
  );
}

const ChartSvg = styled("svg")`
  width: 100%;
  height: 100%;

  .pointer {
    cursor: pointer;
  }
`;

const LegendSvg = styled("svg")`
  position: relative;
  width: 100%;
  height: 100%;


  circle {
    transform-origin: center center;
  }
  .list-item {
    pointer-events: bounding-box;
    }
    // make items grey when they have no value
    .desaturate {
      pointer-events: none;
      opacity: 30%;

      circle, 
      text {
        fill: ${(p) => p.theme.palette.text.primary};
      }
    }
  }
`;
