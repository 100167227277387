import {
  ChangeEvent,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Grid,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { partition } from "lodash-es";
import { useSnackbar } from "notistack";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AssetSearch } from "~/components/asset-search";
import { GroupingButton } from "~/components/grouping-button";
import { ExpandMoreIcon, HomeIcon } from "~/components/icons";
import { VastEmptyness } from "../vast-emptyness/vast-emptyness";
import { FormatRelativeDate, FormatTime } from "~/lib/date";
import {
  AssetForwardPaginationDocument,
  AssetForwardPaginationQuery,
  AssetOrder,
  AssetOrderField,
  LoadSpaceStatsDocument,
  OrderDirection,
  ScoreType,
  useAssetForwardPaginationLazyQuery,
  useDeleteAssetsMutation,
  DocumentType,
  ScoreRating,
} from "~/operations";
import { IamActions } from "~/lib/iam";
import { pluralize } from "~/lib/pluralize";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { useGroupingButton } from "~/components/grouping-button/useGroupingButton";
import {
  ASSET_TABLE_INITIAL_PAGE_RANGE,
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import {
  AssetGroupStatsItem,
  Inventory,
  useInventory,
} from "./hooks/useInventory";
import {
  DataTable,
  LoadingRow,
  SelectionToolbar,
} from "~/components/data-table";
import { useGetAssetsCount } from "./hooks/useAssets";
import { AssetUrlFilter, isAssetUrlFilter } from "~/hooks/useAssetUrlStats";
import {
  Scope,
  ScopeType,
  SpaceOrWorkspaceScope,
  SpaceScope,
} from "~/hooks/useScope";
import { WorkspaceEmpty } from "~/pages/space/Workspaces/components";
import { LoadingPage } from "~/components/loading";
import { ViewSwitcher, ViewType } from "~/components/view-switcher";
import { Flex } from "~/components/Flex";
import { isFeatureEnabled } from "~/login/features";
import { NoMatchFilters } from "~/pages/inventory/components/EmptyState";
import { GenerateReportButton } from "../compliance/components/generate-report";
import { ImpactCell } from "~/components/Cells";
import { setDocumentTitle } from "~/utils/commonUtils";

type AssetsConnection = NonNullable<AssetForwardPaginationQuery["assets"]>;
type AssetNode = NonNullable<NonNullable<AssetsConnection["edges"]>[0]["node"]>;

type Props = {
  scope: SpaceOrWorkspaceScope;
  spaceScope: SpaceScope;
};

export function InventoryPage({ scope, spaceScope }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [riskFilter, setRiskFilter] = useState<ScoreRating[]>([]);
  const [assetUrlFilter, setAssetUrlFilter] = useState<AssetUrlFilter>([]);
  const { toggleSelectedGroupingButton, selectedGroupingButton } =
    useGroupingButton();

  const inventory = useInventory({ scope, searchFilters });

  const searchParamsStorageKey = `${scope.type}[${scope.mrn}].inventory.searchParams`;
  const storableSearchParamKeys = ["groupType", "queryTerms", "assetUrlFilter"];

  const storeSearchParams = (
    searchParams: URLSearchParams,
  ): URLSearchParams | null => {
    const storableSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (storableSearchParamKeys.includes(key)) {
        storableSearchParams.set(key, value);
      }
    });

    if (storableSearchParams.toString().length) {
      sessionStorage.setItem(
        searchParamsStorageKey,
        storableSearchParams.toString(),
      );
      return storableSearchParams;
    }
    sessionStorage.removeItem(searchParamsStorageKey);
    return null;
  };

  const restoreSearchParams = (): URLSearchParams | null => {
    const storedSearchParams = sessionStorage.getItem(searchParamsStorageKey);
    if (storedSearchParams) {
      return new URLSearchParams(storedSearchParams);
    }
    return null;
  };

  useEffect(() => {
    const storedSearchParams = restoreSearchParams();
    const hasStoredSearchParams = storedSearchParams !== null;
    const hasSpecifiedSearchParams = storableSearchParamKeys.some((k) =>
      searchParams.has(k),
    );
    // If there is no search params already specified in the current URL but there is some
    // stored search params from the previous visit, restore the stored search params.
    if (!hasSpecifiedSearchParams && hasStoredSearchParams) {
      storedSearchParams.forEach((value, key) => {
        searchParams.set(key, value);
      });
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  useEffect(() => {
    storeSearchParams(searchParams);

    const searchFilters = searchParams.get("queryTerms");
    setSearchFilters(searchFilters?.split(",") || []);
    const nextRiskFilters = searchParams.get("risk");
    if (nextRiskFilters) {
      setRiskFilter(nextRiskFilters.split(",") as ScoreRating[]);
    } else {
      setRiskFilter([]);
    }

    try {
      const assetUrlFilterParam = searchParams.get("assetUrlFilter") || "";
      const assetUrlFilter = JSON.parse(assetUrlFilterParam);
      if (isAssetUrlFilter(assetUrlFilter)) {
        setAssetUrlFilter(assetUrlFilter);
      }
    } catch (error) {
      setAssetUrlFilter([]);
    }
  }, [searchParams]);

  const {
    loading: assetsLoading,
    count: assetsCount,
    refetch: assetsRefetch,
  } = useGetAssetsCount({
    searchFilters: searchFilters,
    scopeMrn: scope.mrn,
  });

  const {
    loading: groupedAssetsCountLoading,
    count: groupedAssetsCount,
    refetch: groupedAssetsRefetch,
  } = useGetAssetsCount({
    searchFilters: searchFilters,
    scopeMrn: scope.mrn,
    groups: selectedGroupingButton
      ? [{ groupType: selectedGroupingButton }]
      : [],
  });

  const [checked, setChecked] = useState<string[]>([]);

  const onCheckedChange: TableAccordionProps["onCheckedChange"] = (checked) => {
    setChecked(checked);
  };

  const hasDeleteAssetsPermission = spaceScope.iamActions.includes(
    IamActions.ASSETS_DELETEASSETS,
  );

  const hasSelectPermission = hasDeleteAssetsPermission;

  const [deleteAssets] = useDeleteAssetsMutation({
    refetchQueries: [LoadSpaceStatsDocument, AssetForwardPaginationDocument],
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const resetEditing = () => {
    setChecked([]);
  };

  const handleBatchCancel = () => {
    resetEditing();
  };

  const handleBatchDone = () => {
    setDeleteModalOpen(true);
  };

  const handleConfirmCancel = () => {
    setDeleteModalOpen(false);
    resetEditing();
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    const total = checked.length;
    try {
      await deleteAssets({
        variables: { input: { spaceMrn: spaceScope.mrn, assetMrns: checked } },
      });
      enqueueSnackbar(
        `Successfully deleted ${total} ${pluralize("asset", total)}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${pluralize("asset", total)}`, {
        variant: "error",
      });
    } finally {
      setIsDeleting(false);
      setDeleteModalOpen(false);
      resetEditing();
    }
  };

  const onAssetSearchQuery = (searchFilters: string[]) => {
    let updatedFilters = searchFilters.filter(
      (x, index) => searchFilters.indexOf(x) === index,
    );

    const [scores, others] = partition(updatedFilters, (o: string) =>
      o.includes('{"risk":'),
    );

    if (scores.length > 0) {
      const array = scores.map((x) => {
        const thing = JSON.parse(x);
        return thing.risk;
      });
      searchParams.set("risk", array.join(","));
    } else {
      searchParams.delete("risk");
    }

    const [graphs, queryTerms] = partition(others, (o: string) =>
      o.includes('{"graph":'),
    );

    if (graphs.length > 0) {
      const assetUrlFilter = graphs.map((x) => {
        const [key, value] = JSON.parse(x).graph.split(":");
        return { key, value };
      });
      searchParams.set("assetUrlFilter", JSON.stringify(assetUrlFilter));
    } else {
      searchParams.delete("assetUrlFilter");
    }

    if (queryTerms.length < 1) {
      searchParams.delete("queryTerms");
    } else {
      searchParams.set("queryTerms", queryTerms.join(","));
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  const totalCount = assetsCount;
  const hasAssets = assetsCount > 0;
  const hasAppliedFilters = searchFilters.length > 0;

  // TODO: handle not have any WORKSPACE assets because aggregateScores haven't refreshed yet
  // If we don't have any assets, we want to show the Vast Emptyness page
  if (!assetsLoading) {
    // If we don't have any assets and we have not applied any filters
    if (!hasAssets && !hasAppliedFilters && scope.type === ScopeType.Space) {
      const VastEmptyFleetOptions = {
        id: "fleet",
        headline: "WELCOME TO MONDOO",
        tagline:
          "To begin scanning for vulnerabilities, let's integrate your infrastructure with Mondoo.",
        buttonText: "Start Scanning",
        href: `/space/integrations/add/mondoo/setup?${spaceScope.params}`,
        buttonText2: "Browse Integrations",
        hrefButton2: `/space/integrations/add?${spaceScope.params}`,
      };

      return <VastEmptyness options={VastEmptyFleetOptions} />;
    }
  }

  const content = (
    <Box>
      <Grid container spacing={2} sx={{ mb: 6 }}>
        {inventory.assetGroupStats.map((group) => {
          return (
            <Grid item key={group.groupType} xs={12} sm={6} md={4}>
              <GroupingButton
                group={group}
                onClick={() => toggleSelectedGroupingButton(group.groupType)}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box
        mb={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AssetSearch
          scopeMrn={scope.mrn}
          filters={searchFilters}
          riskFilter={searchParams.get("risk")?.split(",") || []}
          assetUrlFilter={assetUrlFilter}
          onQuery={onAssetSearchQuery}
          searchPlaceholder="inventory"
        />
      </Box>
      <Box>
        <Flex mb={4} justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center", my: -2 }}>
            <Typography color="text.secondary">
              Showing {groupedAssetsCount} of {assetsCount}{" "}
              {pluralize("asset", groupedAssetsCount)}
            </Typography>
          </Box>
          <Box>
            <ViewSwitcher
              views={[ViewType.List, ViewType.Grouped]}
              defaultView={ViewType.Grouped}
            />
          </Box>
        </Flex>
        <Tables
          {...{
            scope: scope,
            assetGroupStats: inventory.assetGroupStats,
            selected: selectedGroupingButton,
            checked,
            onCheckedChange,
            hasSelectPermission,
            riskFilter,
            assetUrlFilter,
          }}
          queryTerms={searchFilters}
        />
        {checked.length > 0 && (
          <SelectionToolbar>
            <Typography>
              Selected {checked.length} of {totalCount} assets
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBatchDone}
            >
              Delete
            </Button>
            <Button onClick={handleBatchCancel}>Cancel</Button>
          </SelectionToolbar>
        )}
        <DeleteConfirmDialog
          open={deleteModalOpen}
          title="Are you sure?"
          content="If you delete an asset from Mondoo, you delete all data related to the asset. You can re-add the asset, but doing so does not recover historical data."
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmCancel}
          isDeleting={isDeleting}
          dividers={false}
        />
      </Box>
    </Box>
  );

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={1}>Inventory</Typography>,
  ];

  setDocumentTitle([scope.name, "Inventory"]);

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
        <Breadcrumbs sx={{ overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        {isFeatureEnabled("Reporting") && spaceScope && (
          <GenerateReportButton
            documentType={DocumentType.SecurityReport}
            title={"Generated Assets Report"}
            space={spaceScope}
          />
        )}
      </Flex>
      {(assetsLoading || groupedAssetsCountLoading) && (
        <LoadingPage what="Inventory" />
      )}
      {!assetsLoading && !groupedAssetsCountLoading && (
        <Box>
          {hasAssets && content}
          {!hasAssets && scope.type === ScopeType.Space && content}
          {!hasAssets && scope.type === ScopeType.Workspace && (
            <>
              {searchFilters.length > 0 ? (
                content
              ) : (
                <WorkspaceEmpty
                  space={spaceScope}
                  scope={scope}
                  onRefreshComplete={() => {
                    inventory.refetch();
                    assetsRefetch();
                    groupedAssetsRefetch();
                  }}
                />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

///////////////////////////////////////
// TABLES CONTAINER COMPONENT

type TablesProps = {
  scope: Scope;
  assetGroupStats: Inventory["assetGroupStats"];
  selected: string | null;
  queryTerms: string[];
  checked: string[];
  onCheckedChange: TableAccordionProps["onCheckedChange"];
  hasSelectPermission: boolean;
  riskFilter: ScoreRating[];
  assetUrlFilter: AssetUrlFilter;
};
const Tables = ({
  scope,
  assetGroupStats,
  selected,
  queryTerms,
  checked,
  onCheckedChange,
  hasSelectPermission,
  riskFilter,
  assetUrlFilter,
}: TablesProps) => {
  const [list, setList] = useState<AssetGroupStatsItem | null>(null);
  const [searchParams] = useSearchParams();
  const selectedView = searchParams.get("view") || ViewType.Grouped;

  useEffect(() => {
    if (!selected) {
      setList(null);
    } else {
      const newList =
        assetGroupStats.find((x) => x.groupType === selected) || null;
      setList(newList);
    }
  }, [assetGroupStats, selected]);

  const buildTitle = () => {
    const isSearching = Boolean(
      queryTerms.length > 0 ||
        riskFilter.length > 0 ||
        assetUrlFilter.length > 0,
    );
    if (selected && isSearching) {
      return `Search Results in ${selected}`;
    } else if (isSearching) {
      return "Search Results";
    } else {
      return "All Assets";
    }
  };

  const groupStats = (list: AssetGroupStatsItem | null) => {
    return (
      list?.listsAssetTypes.filter(
        (x) =>
          list.statistics?.find((y) => y.type.assetType === x.assetType)?.count,
      ) || []
    );
  };

  const k8sGroupStats = (list: AssetGroupStatsItem | null) => {
    return (
      list?.statistics
        ?.filter((x) => x.count > 0)
        .filter(
          (x) =>
            x.type.assetType.startsWith("k8s.") ||
            x.type.assetType === "_other_",
        ) || []
    );
  };

  // Expanded TableAccordions are stored as array of `groupKey` strings
  // containing `groupType` and `assetType`, or `_all_` for TableAccordion
  // displayed while selected `groupType` is null.
  const expandedStorageKey = `${scope.type}[${scope.mrn}].inventory.expanded`;
  const allGroupKey = "_all_";
  const getGroupKey = (groupType: string, assetType: string): string => {
    return `${groupType}.${assetType}`;
  };

  const [expanded, setExpanded] = useState<string[]>([allGroupKey]);

  const isGroupExpanded = (groupKey: string) => {
    return expanded.includes(groupKey);
  };

  const onExpandedChange: TableAccordionProps["onExpandedChange"] = (
    groupKey,
    isExpanded,
  ) => {
    const nextExpanded = !isExpanded
      ? expanded.filter((a) => a !== groupKey)
      : [...expanded, groupKey];
    setExpanded(nextExpanded);
    storeExpanded(nextExpanded);
  };

  const storeExpanded = (expanded: string[]): string[] | null => {
    if (expanded.length > 0) {
      sessionStorage.setItem(expandedStorageKey, expanded.join(","));
      return expanded;
    }
    sessionStorage.removeItem(expandedStorageKey);
    return null;
  };

  const restoreExpanded = (): string[] | null => {
    const storedExpanded = sessionStorage.getItem(expandedStorageKey);
    if (storedExpanded) {
      return storedExpanded.split(",");
    }
    return null;
  };

  useEffect(() => {
    const storedExpanded = restoreExpanded();
    if (storedExpanded !== null) {
      setExpanded(storedExpanded);
    }
  }, []);

  useEffect(() => {
    const expandedInGroup = expanded.filter((groupKey) =>
      list === null
        ? groupKey === allGroupKey
        : groupKey.split(".").shift() === list.groupType,
    );
    // If no assetTypes are already expanded in the selected groupType,
    // auto-expand the first assetType within the groupType
    if (expandedInGroup.length === 0) {
      const nextExpanded = [...expanded];
      if (list === null) {
        nextExpanded.push(allGroupKey);
      } else if (list.groupType === "k8s") {
        const firstGroup = k8sGroupStats(list)[0];
        if (firstGroup) {
          nextExpanded.push(
            getGroupKey(list.groupType, firstGroup.type.assetType),
          );
        }
      } else {
        const firstGroup = groupStats(list)[0];
        if (firstGroup) {
          nextExpanded.push(getGroupKey(list.groupType, firstGroup.assetType));
        }
      }

      setExpanded(nextExpanded);
      storeExpanded(nextExpanded);
    }
  }, [list]);

  if (
    !list ||
    !selected ||
    queryTerms.length > 0 ||
    selectedView === ViewType.List
  ) {
    return (
      <Box>
        <MotionDiv>
          <TableAccordion
            index={0}
            key={allGroupKey}
            groupKey={allGroupKey}
            expanded={isGroupExpanded(allGroupKey)}
            onExpandedChange={onExpandedChange}
            scope={scope}
            title={buildTitle()}
            groupType={selected || undefined}
            queryTerms={queryTerms}
            riskFilter={riskFilter}
            assetUrlFilter={assetUrlFilter}
            checked={checked}
            onCheckedChange={onCheckedChange}
            hasSelectPermission={hasSelectPermission}
            hasGroups={assetGroupStats.length > 0}
          />
        </MotionDiv>
      </Box>
    );
  }

  if (selected === "k8s") {
    return (
      <Box>
        {k8sGroupStats(list).map((x, index) => {
          const reg = new RegExp(`^K8s`);
          const displayName = pluralize(x.type.displayName.replace(reg, ""), 2);
          const groupKey = getGroupKey("k8s", x.type.assetType);

          return (
            <MotionDiv index={index} key={index}>
              <TableAccordion
                index={index}
                defaultExpanded={Boolean(index < 1)}
                scope={scope}
                key={groupKey}
                groupKey={groupKey}
                expanded={isGroupExpanded(groupKey)}
                onExpandedChange={onExpandedChange}
                title={displayName}
                count={x.count}
                groupType={selected}
                assetType={x.type.assetType}
                riskFilter={riskFilter}
                assetUrlFilter={assetUrlFilter}
                checked={checked}
                onCheckedChange={onCheckedChange}
                hasSelectPermission={hasSelectPermission}
                hasGroups={assetGroupStats.length > 0}
              />
            </MotionDiv>
          );
        })}
      </Box>
    );
  }

  return (
    <Box>
      {groupStats(list).map((x, index) => {
        const groupKey = getGroupKey(selected, x.assetType);
        const count =
          list?.statistics?.find((y) => y.type.assetType === x.assetType)
            ?.count || 0;

        const pluralizedTitle = pluralize(x.displayName, 2);

        return (
          <MotionDiv index={index} key={index}>
            <TableAccordion
              index={index}
              key={groupKey}
              groupKey={groupKey}
              expanded={isGroupExpanded(groupKey)}
              onExpandedChange={onExpandedChange}
              scope={scope}
              title={pluralizedTitle}
              count={count}
              groupType={selected}
              assetType={x.assetType}
              riskFilter={riskFilter}
              assetUrlFilter={assetUrlFilter}
              checked={checked}
              onCheckedChange={onCheckedChange}
              hasSelectPermission={hasSelectPermission}
              hasGroups={assetGroupStats.length > 0}
            />
          </MotionDiv>
        );
      })}
    </Box>
  );
};

const MotionDiv = ({
  children,
  index = 0,
}: {
  children: ReactNode;
  index?: number;
}) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: index * 0.025 }}
    >
      {children}
    </Box>
  );
};

type TableAccordionProps = {
  groupKey: string;
  index: number;
  scope: Scope;
  title: string;
  assetType?: string;
  groupType?: string;
  count?: number;
  onExpandedChange: (groupKey: string, isExpanded: boolean) => void;
  queryTerms?: string[];
  riskFilter?: ScoreRating[];
  assetUrlFilter: AssetUrlFilter;
  checked: string[];
  onCheckedChange: (checked: string[]) => void;
  hasSelectPermission: boolean;
  hasGroups: boolean;
} & Omit<AccordionProps, "children">;

const TableAccordion = ({
  groupKey,
  index,
  scope,
  title,
  count,
  expanded = false,
  onExpandedChange,
  queryTerms = [],
  riskFilter = [],
  assetUrlFilter = [],
  groupType,
  assetType,
  checked,
  onCheckedChange,
  hasSelectPermission,
  hasGroups,
  ...rest
}: TableAccordionProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageItems, setPageItems] = useState<PaginationRange>(
    ASSET_TABLE_INITIAL_PAGE_RANGE,
  );
  const pageSize = pageItems.to - pageItems.from;
  const isFiltering = riskFilter.length > 0 || queryTerms.length > 0;

  const sort: AssetOrder = {
    field:
      (searchParams.get("field") as AssetOrderField) ||
      AssetOrderField.LastUpdated,
    direction:
      searchParams.get("direction") === "ASC"
        ? OrderDirection.Asc
        : OrderDirection.Desc,
  };

  const groups =
    groupType && assetType
      ? [
          {
            groupType,
            assetTypes: [assetType],
          },
        ]
      : groupType && !assetType
        ? [{ groupType }]
        : [];

  const [fetchData, { data, loading, fetchMore }] =
    useAssetForwardPaginationLazyQuery({
      variables: {
        scopeMrn: scope.mrn,
        scoreType: ScoreType.Unknown,
        rating: riskFilter,
        platformName: [],
        platformKind: [],
        platformRuntime: [],
        labels: [],
        eol: null,
        reboot: null,
        exploitable: null,
        updated: null,
        orderBy: sort,
        queryTerms: queryTerms,
        groups: groups,
        assetUrlFilter,
        first: pageSize,
      },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (expanded || riskFilter.length > 0) {
      fetchData();
    }
  }, [expanded, riskFilter]);

  const handleAccordionChange =
    () => (_event: SyntheticEvent, isExpanded: boolean) => {
      onExpandedChange(groupKey, isExpanded);
    };

  const handleSortClick = (header: string) => {
    searchParams.set("field", header);
    searchParams.set(
      "direction",
      header !== sort.field
        ? "DESC"
        : sort.direction === "ASC"
          ? "DESC"
          : "ASC",
    );
    setSearchParams(searchParams);
  };

  const isAssetChecked = (asset?: AssetNode | null) => {
    if (!asset) return false;
    return checked.includes(asset.mrn);
  };

  const isGroupChecked = (assets?: AssetsConnection | null) => {
    if (!assets?.edges) return false;
    return assets.edges
      .slice(pageItems.from, pageItems.to)
      .every((edge) => isAssetChecked(edge.node));
  };

  const isGroupIndeterminate = (assets?: AssetsConnection | null) => {
    if (!assets?.edges) return false;
    if (isGroupChecked(assets)) return false;
    return assets.edges
      .slice(pageItems.from, pageItems.to)
      .some((edge) => isAssetChecked(edge.node));
  };

  const onGroupCheckChange = (
    _event: ChangeEvent<HTMLInputElement>,
    assets?: AssetsConnection | null,
  ) => {
    const assetMrns =
      assets?.edges
        ?.slice(pageItems.from, pageItems.to)
        .map((e) => e.node?.mrn)
        .flatMap((mrn) => (mrn ? [mrn] : [])) || [];

    if (isGroupChecked(assets) || isGroupIndeterminate(assets)) {
      // uncheck all
      onCheckedChange(checked.filter((mrn) => !assetMrns.includes(mrn)));
    } else {
      // check all
      onCheckedChange([
        ...checked,
        ...assetMrns.filter((mrn) => !checked.includes(mrn)),
      ]);
    }
  };

  const onAssetCheckChange = (
    _event: ChangeEvent<HTMLInputElement>,
    asset?: AssetNode | null,
  ) => {
    if (!asset) return;
    if (isAssetChecked(asset)) {
      onCheckedChange(checked.filter((mrn) => mrn !== asset.mrn));
    } else {
      onCheckedChange([...checked, asset.mrn]);
    }
  };

  const totalChecked =
    data?.assets?.edges?.filter((edge) => isAssetChecked(edge.node)).length ||
    0;

  const assets = data?.assets?.edges?.flatMap((edge) => edge.node ?? []) || [];

  if (assets.length === 0 && queryTerms?.length > 0 && !loading) {
    return (
      <NoMatchFilters
        title="No Assets Match Your Search"
        content={
          hasGroups
            ? "No assets in the selected group(s) match your search terms. Select a different group to see matching assets in that group, or clear all search filters to show all assets."
            : `No assets match your search terms. Clear all search filters to show all assets.`
        }
        onClick={() => {
          searchParams.delete("queryTerms");
          searchParams.delete("groupType");
          setSearchParams(searchParams);
        }}
      />
    );
  }

  return (
    <Box mb={3}>
      <Accordion
        {...rest}
        expanded={expanded}
        onChange={handleAccordionChange()}
      >
        <AccordionSummary
          sx={{
            backgroundColor: "background.light",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            flexDirection: "row-reverse",
            "& .Mui-expanded .expand-more-icon": {
              transform: "rotate(-180deg)",
            },
            "&.Mui-expanded": {
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              fontWeight={700}
              sx={{ mr: 0.5, textTransform: "uppercase" }}
            >
              {title}
            </Typography>
            <Typography fontWeight={700} color="text.secondary">
              {isFiltering
                ? `${data?.assets?.edges?.length || 0} of ${
                    count || data?.assets?.totalCount
                  }`
                : count || data?.assets?.totalCount}
              {totalChecked > 0 && ` (${totalChecked} selected)`}
            </Typography>
            <ExpandMoreIcon
              className="expand-more-icon"
              sx={{ transform: "rotate(0deg)", transition: "transform .25s" }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 0 }}>
          <Fragment>
            {" "}
            <DataTable
              id={`fleet-table-${title.split(" ").join("-").toLowerCase()}`}
              selectable={hasSelectPermission}
              selection={checked}
            >
              <TableHead>
                <TableRow>
                  {hasSelectPermission && (
                    <TableCell>
                      <Checkbox
                        checked={isGroupChecked(data?.assets)}
                        indeterminate={isGroupIndeterminate(data?.assets)}
                        onChange={(event) =>
                          onGroupCheckChange(event, data?.assets)
                        }
                      />
                    </TableCell>
                  )}
                  <TableCell width="5%">
                    <TableSortLabel
                      onClick={() => handleSortClick(AssetOrderField.RiskValue)}
                      direction={sort.direction === "ASC" ? "asc" : "desc"}
                      active={sort.field === AssetOrderField.RiskValue}
                    >
                      Risk
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="45%">
                    {" "}
                    <TableSortLabel
                      onClick={() => handleSortClick(AssetOrderField.Name)}
                      direction={sort.direction === "ASC" ? "asc" : "desc"}
                      active={sort.field === AssetOrderField.Name}
                    >
                      Asset name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="20%">
                    <TableSortLabel
                      onClick={() => handleSortClick(AssetOrderField.Platform)}
                      direction={sort.direction === "ASC" ? "asc" : "desc"}
                      active={sort.field === AssetOrderField.Platform}
                    >
                      Platform
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="25%" sx={{ pr: 4 }}>
                    {" "}
                    <TableSortLabel
                      onClick={() =>
                        handleSortClick(AssetOrderField.LastUpdated)
                      }
                      direction={sort.direction === "ASC" ? "asc" : "desc"}
                      active={sort.field === AssetOrderField.LastUpdated}
                    >
                      Last updated
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <LoadingRow
                    colSpan={hasSelectPermission ? 5 : 4}
                    what={title}
                  />
                )}
                {assets.slice(pageItems.from, pageItems.to).map((asset) => {
                  const formattedDate = asset.updatedAt
                    ? `${FormatRelativeDate(asset.updatedAt)} - ${FormatTime(
                        asset.updatedAt,
                      )}`
                    : "";
                  const assetHref = `/space/inventory/${asset.id}/overview?${scope.params}`;
                  const isSelected = isAssetChecked(asset);
                  const className = isSelected ? "selected" : "";
                  return (
                    <TableRow
                      key={asset.id}
                      className={className}
                      onClick={() => navigate(assetHref)}
                    >
                      {hasSelectPermission && (
                        <TableCell>
                          <Checkbox
                            checked={isSelected}
                            onChange={(event) =>
                              onAssetCheckChange(event, asset)
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                        </TableCell>
                      )}
                      <ImpactCell
                        impact={asset.score.riskValue}
                        rating={asset.score.riskRating}
                        isActive
                      />
                      <TableCell sx={{ overflowWrap: "anywhere" }}>
                        {asset.name}
                      </TableCell>
                      <TableCell>{asset.platform?.name}</TableCell>
                      <TableCell sx={{ pr: 4 }}>{formattedDate}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </DataTable>
            {data?.assets?.pageInfo && (
              <Pagination
                fetchMore={fetchMore}
                pageInfo={data?.assets?.pageInfo}
                totalCount={data?.assets?.totalCount || 0}
                setPageItems={setPageItems}
                defaultPageSize={ASSET_TABLE_INITIAL_PAGE_RANGE.to}
              />
            )}
          </Fragment>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
