import { useMemo, useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  NavigateFunction,
  useParams as useParamsReact,
} from "react-router-dom";
import { isBoolean } from "lodash-es";
import { Obj, Route, parseQueryParam } from "~/components/ui-library";

type UseParamsProps = Obj;

type UseParamsReturnProps = {
  isRouterReady: boolean;
  router: {
    isReady: boolean;
    query: Obj;
    pathname: string;
    replace: (val: string) => void;
    navigate: NavigateFunction;
    navigateTo: (val: Route["href"]) => void;
  };
  facetsFromQueryString: Obj | null;
};

const useParams = (defaultParams?: UseParamsProps): UseParamsReturnProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const paramsReact = useParamsReact();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRouterReady, setIsRouterReady] = useState(false);

  const router = useMemo(() => {
    const isReady = true;
    const query: Obj = {
      ...paramsReact,
    };
    searchParams.forEach((value, key) => {
      query[key] = value;
    });
    const pathname = location.pathname;

    const replace = (search: string) => {
      navigate({ pathname, search }, { replace: true });
    };

    const navigateTo = (href: Route["href"]) => {
      let pathname = href?.pathname || "";
      let query = href?.query || {};
      let searchParams = "";

      Object.keys(query).forEach((key) => {
        const value = query[key];

        if (pathname.includes(`[${key}]`)) {
          // params
          pathname = pathname.replace(`[${key}]`, value);
        } else {
          // searchParams
          const separator = searchParams ? `&` : ``;
          searchParams += `${separator}${key}=${value}`;
        }
      });

      const to = searchParams ? `${pathname}?${searchParams}` : pathname;

      // ToDo: Shouldn't handle adding `/space` route like this.
      navigate("/space" + to);
    };

    return {
      isReady,
      query,
      pathname,
      replace,
      navigate,
      navigateTo,
    };
  }, [searchParams, location, paramsReact]);

  useEffect(() => {
    if (router.isReady && !isRouterReady) {
      setIsRouterReady(true);
    }
  }, [router.isReady]);

  const facetsFromQueryString = useMemo(() => {
    if (isRouterReady && defaultParams) {
      return parseQueryString(defaultParams, router);
    }

    return null;
  }, [isRouterReady, defaultParams]);

  return {
    router,
    isRouterReady,
    facetsFromQueryString,
  };
};

export default useParams;

const parseQueryString = (
  defaultParams: Obj,
  router: UseParamsReturnProps["router"],
) => {
  return Object.keys(defaultParams).reduce((acc, key) => {
    const value = defaultParams[key];

    const parsedValue = parseQueryParam({
      query: router.query[key],
      value: isBoolean(value) ? String(value) : value,
    });

    acc[key] =
      parsedValue === "true"
        ? true
        : parsedValue === "false"
          ? false
          : parsedValue;

    return acc;
  }, {} as Obj);
};
