import { isString, isArray, isNumber } from "lodash-es";
import isObject from "./isObject";

type parseQueryParamProps = {
  query: string | string[] | undefined;
  value: string | (string | number)[] | number;
};

/**
 * Prase queryString-value based on the type of given value.
 */
const parseQueryParam = ({
  // `query` is query-value from queryString.
  query,
  // When `query` is not there, `value` will be returned based on value-type.
  value,
}: parseQueryParamProps): parseQueryParamProps["value"] => {
  try {
    if (isString(value)) {
      return query || value;
    } else if (isArray(value)) {
      return query ? String(query).split(",") : value;
    } else if (isNumber(value)) {
      return query ? Number(query) : value;
    } else if (isObject(value)) {
      return query ? JSON.parse(String(query)) : value;
    }
  } catch (error) {
    return value;
  }

  return value;
};

export default parseQueryParam;
