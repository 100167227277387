import { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, keyframes, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { CopyIcon, SaveAltIcon } from "~/components/icons";
import { copyToClipboard } from "~/lib/clipboard";
import { ApolloError } from "@apollo/client";
import { getError } from "~/lib/handle-error";
import { useGeneratedCode } from "./hooks/useGeneratedCode";
import { DisplayedError } from "./DisplayedError";
import hljs from "highlight.js/lib/core";
import yaml from "highlight.js/lib/languages/yaml";
import { syntaxStyle } from "~/components/code/syntaxStyle";

hljs.registerLanguage("yaml", yaml);

export function GeneratedCode({
  code,
  error,
}: {
  code?: string;
  error?: ApolloError;
}) {
  let theme = useTheme();
  const { handleDownload } = useGeneratedCode();
  const [lines, setLines] = useState<string[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [displayError, setDisplayError] = useState<string | null>(null);

  useEffect(() => {
    if (!code) return;
    const newLines = code.split("\n");
    setLines(newLines);

    // Show all lines after 3 seconds if the code is long
    if (newLines.length > 20) {
      setTimeout(() => {
        setShowAll(true);
      }, 3000);
    }
  }, [code]);

  useEffect(() => {
    if (error) {
      const errorMessage = getError(error);
      setDisplayError(
        errorMessage ||
          "An unknown error occurred while generating the policy.",
      );
    }
  }, [error]);

  const glimmer = keyframes`
     0%: { backgroundPosition: "0% 0%" },
    100%: { backgroundPosition: "100% 0%" },
  `;

  const backgroundCodeBlockFadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 1, delay: 0.5 },
  };

  return (
    <>
      {/* if there as error, we'll display the error */}
      {displayError && !code && <DisplayedError error={displayError} />}

      {/* if we get code back - which is a success, we'll start the code animation */}
      {code && (
        <Box
          mt={2}
          p={2}
          sx={{
            position: "relative",
            borderRadius: 1,
          }}
        >
          {/* background object to fade in as code block */}
          <Box
            component={motion.div}
            {...backgroundCodeBlockFadeIn}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: theme.palette.code.background,
              zIndex: -1,
              borderRadius: "4px",
            }}
          />
          <Box
            component={motion.div}
            {...backgroundCodeBlockFadeIn}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 1,
            }}
          >
            <Button sx={{ fontSize: 10 }} onClick={() => copyToClipboard(code)}>
              <CopyIcon sx={{ mr: 0.5, fontSize: 12 }} />
              copy
            </Button>
            <Button sx={{ fontSize: 10 }} onClick={() => handleDownload(code)}>
              <SaveAltIcon sx={{ mr: 0.5, fontSize: 12 }} />
              download
            </Button>
          </Box>

          {/* If there is not a lot of code, we'll animate everything */}
          {!showAll && (
            <>
              {lines.map((line, lineIndex) => (
                <Box
                  key={lineIndex}
                  sx={{ position: "relative", height: 16, mb: 1 }}
                >
                  {/* Rainbow glimmer layer (fades out after animation) */}
                  <motion.div
                    initial={{
                      clipPath: "polygon(0% 0%, 0% 0%, 0% 0%)",
                      opacity: 1,
                      scale: 0.98,
                    }}
                    animate={{
                      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                      opacity: 0,
                      scale: 1,
                    }}
                    transition={{
                      duration: 1.0,
                      delay: lineIndex * 0.05,
                      ease: "easeInOut",
                      opacity: { duration: 0.5, delay: lineIndex * 0.05 + 0.6 },
                      scale: { duration: 1.0 },
                    }}
                    style={{
                      position: "absolute",
                      width: "100%",
                      transformOrigin: "top left",
                      pointerEvents: "none",
                    }}
                  >
                    <Typography
                      component="pre"
                      sx={{
                        fontFamily: "monospace",
                        fontSize: "16px",
                        m: 0,
                        whiteSpace: "pre-wrap",
                        background:
                          "linear-gradient(90deg, #9147FF, #4C35E8, #FC3779, #2CBFC9, #9147FF)",
                        backgroundSize: "300% 100%",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        animation: `${glimmer} 1.2s ease forwards`,
                        filter: "blur(0.8px)",
                        textShadow: `
                    0 0 4px #9147FF,
                    0 0 8px #FC3779,
                    0 0 12px #2CBFC9
                  `,
                      }}
                    >
                      {line || " "}
                    </Typography>
                  </motion.div>

                  {/* Final static text layer - fades in */}
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      delay: lineIndex * 0.05 + 0.8,
                      duration: 0.4,
                    }}
                    style={{
                      position: "absolute",
                      width: "100%",
                    }}
                  >
                    <LineOfCode key={lineIndex} line={line || ""} />
                  </motion.div>
                </Box>
              ))}
            </>
          )}
          {/* if text is long, cancel the animation after 3 seconds and show rest of code at once */}
          {showAll && (
            <Box sx={{ ...syntaxStyle(), ".hljs": { borderColor: "none" } }}>
              <HighlightedYaml code={code} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

const LineOfCode = ({ line }: { line: string }) => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current) {
      hljs.highlightElement(ref.current);
    }
  }, [line]);

  return (
    <Typography
      component="pre"
      sx={{
        fontFamily: "monospace",
        fontSize: "16px",
        m: 0,
        whiteSpace: "pre-wrap",
      }}
    >
      <code ref={ref} className="language-yaml">
        {line}
      </code>
    </Typography>
  );
};

export function HighlightedYaml({ code }: { code: string }) {
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, [code]);

  return (
    <pre>
      <code ref={codeRef} className="language-yaml">
        {code}
      </code>
    </pre>
  );
}
