import React, { useState } from "react";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { useFindingsTable } from "~/pages/inventory/asset/AssetVulnerabilitiesTable";
import { DataTable } from "~/components/data-table";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  FindingsOrder,
  PageInfo,
  TestIamActionsQuery,
  useGetAdvisoriesQuery,
  useGetChecksQuery,
  useGetCvEsQuery,
} from "~/operations";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";
import { CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { FindingsTableRow } from "~/pages/inventory/components/Findings/FindingsTable/FindingsTableRow";
import { FindingContext } from "~/pages/inventory/components/Findings/FindingsTable/findingTableUtils";

type FindingsTableProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  onSort: (id: string) => void;
  orderBy: FindingsOrder;
  fetchMore:
    | ReturnType<typeof useGetCvEsQuery>["fetchMore"]
    | ReturnType<typeof useGetAdvisoriesQuery>["fetchMore"]
    | ReturnType<typeof useGetChecksQuery>["fetchMore"];
  findings:
    | CVEFindingsNode[]
    | AdvisoryFindingsNode[]
    | CheckFindingsCheckNode[];
  pageInfo: PageInfo | undefined;
  totalCount: number;
  assetMrn?: string;
  context: FindingContext;
  assetId?: string;
};

export const FindingsTable = ({
  findings,
  assetMrn,
  availablePermissions,
  totalCount,
  onSort,
  orderBy,
  fetchMore,
  pageInfo,
  context,
  assetId,
}: FindingsTableProps) => {
  const navigate = useNavigate();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const paginatedFindings = findings.slice(pageItems.from, pageItems.to);

  const {
    hasApplyExceptionPermission,
    tableHeaders,
    exceptionsSelection,
    getFindingsTableRowHref,
    getFindingTableRowExceptionHref,
    exception,
    exceptionGroups,
    findingExceptionTarget,
    jobId,
    projectId,
  } = useFindingsTable({
    availablePermissions,
    context,
    entityMrn: assetMrn || "",
    items: paginatedFindings,
  });

  const buildTableRowHref = getFindingsTableRowHref({
    assetId,
    jobId,
    projectId,
  });

  return (
    <>
      <DataTable
        id="asset-findings-table"
        selectable={hasApplyExceptionPermission}
        selection={exceptionsSelection.selectedEntities}
      >
        <TableHead
          tableHeaders={tableHeaders}
          onSort={onSort}
          orderBy={orderBy}
        />
        <TableBody>
          {paginatedFindings.map((finding) => {
            return (
              <FindingsTableRow
                key={finding.mrn}
                finding={finding}
                context={context}
                canSelect={hasApplyExceptionPermission}
                isSelected={Boolean(
                  exceptionsSelection.selectedEntities.find(
                    (selectedCheck) => finding.mrn === selectedCheck.mrn,
                  ),
                )}
                onClick={() => {
                  navigate(buildTableRowHref(finding));
                }}
                onCheckboxClick={(e) => exceptionsSelection.handleNodeClick(e)}
                onCheckboxSelect={(e, changed) =>
                  exceptionsSelection.handleNodeChange(e, changed, {
                    groupId: finding.exception?.id,
                    mrn: finding.mrn,
                    exception: finding.exception,
                  })
                }
                onPendingExceptionClick={() => {
                  navigate(
                    getFindingTableRowExceptionHref(
                      finding.exception?.id || "",
                      assetId,
                    ),
                  );
                }}
              />
            );
          })}
        </TableBody>
      </DataTable>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={totalCount}
        setPageItems={setPageItems}
      />
      {exceptionsSelection.selectedEntities.length > 0 && (
        <ExceptionsToolbar
          onCancel={exceptionsSelection.handleCancelClick}
          onRemoveExceptionClick={exception.handleRemoveExceptionModalOpen}
          onSetExceptionClick={exception.handleSetExceptionModalOpen}
          selectedEntities={exceptionsSelection.selectedEntities}
          totalCount={totalCount}
          target={findingExceptionTarget}
        />
      )}
      <ExceptionsModals
        isSetExceptionModalOpen={exception.isSettingException}
        isRemoveExceptionModalOpen={exception.isRemovingException}
        onRemoveExceptionModalClose={exception.handleRemoveExceptionModalClose}
        onSetExceptionModalClose={exception.handleSetExceptionModalClose}
        onSetExceptionModalSave={exception.handleSetException}
        onRemoveExceptionModalSave={exception.handleRemoveException}
        loading={exception.loading}
        target={findingExceptionTarget}
        role="asset"
        exceptionGroups={exceptionGroups}
        selectedEntities={exceptionsSelection.selectedEntities}
      />
    </>
  );
};
