import { Box, alpha, useTheme } from "@mui/material";
import { get } from "lodash-es";

export type BlastMeterProps = {
  /**
   * Minimum value of blast range. Defaults to `0`.
   */
  min?: number;
  /**
   * Maximum value of blast range. Defaults to `1`.
   */
  max?: number;
  /**
   * Current value of blast range. Defaults to `0`.
   */
  value?: number;
  /**
   * Threshold values within blast range. Defaults to `{ low: 0.05, high: 0.2 }`.
   */
  thresholds?: { [name: string]: number };
  /**
   * Color of blast radius icon. CSS color strings or theme palette strings are accepted. Defaults to `primary.main`.
   */
  color?: string;
  /**
   * Size of blast radius icon. Defaults to `24px`.
   */
  size?: number;
  /**
   * Provide aria-label to blast radius icon. Defaults to `undefined`.
   */
  label?: string;
  /**
   * Associate labelled element to blast radius icon. Defaults to `undefined`.
   */
  labelledBy?: string;
};

export function BlastMeter({
  min = 0,
  max = 1,
  value = 0,
  thresholds = { low: 0.05, high: 0.2 },
  color = "primary.main",
  size = 24,
  label,
  labelledBy,
}: BlastMeterProps) {
  const theme = useTheme();
  const ringSize = 8;
  const ringThresholds = [min, ...Object.values(thresholds).sort()];
  const viewBoxSize = ringSize * ringThresholds.length;
  const fillColor = get(theme.palette, color, color);
  return (
    <Box
      className="blast-meter"
      sx={{
        display: "inline-flex",
        alignItems: "center",

        ".threshold": {
          fill: "transparent",
          stroke: (theme) => theme.palette.background.lightest,
          vectorEffect: "non-scaling-stroke",

          "&.filled": {
            stroke: fillColor,

            "&:first-of-type": {
              fill: alpha(fillColor, 0.5),
            },
          },
        },
      }}
      role="meter"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-label={label}
      aria-labelledby={labelledBy}
    >
      <Box
        className="blast-radius-icon"
        component="svg"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        width={`${size}px`}
        overflow="visible"
        aria-hidden
      >
        {ringThresholds.map((threshold, i) => (
          <circle
            key={`threshold-${i}`}
            className={`threshold ${value > threshold ? "filled" : ""}`}
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={(ringSize / 2) * i + ringSize / 2}
          />
        ))}
      </Box>
    </Box>
  );
}
