import { Link, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { isFeatureEnabled } from "~/login/features";
import { OpenInNewIcon } from "~/components/icons";
import { GithubIntegrationType, VpcFlavour } from "~/operations";
import { stackUrl } from "~/pages/integration-setup";
import { ConfigurationsAndMessagesProps } from "../components/configurations-and-messages";
import { FormatDateShort } from "~/lib/date";
import { capitalizeFirstLetter } from "~/lib/helpers";

export function useConfigurationsAndMessages({
  integration,
  token,
  actionType,
}: ConfigurationsAndMessagesProps) {
  let displayedConfigs: Record<string, unknown> = {};

  const configOptions = integration.configurationOptions;
  const configTypename = configOptions?.__typename;

  const formatConfigName = (config: string) => {
    switch (config.toLowerCase()) {
      case "accountid":
        return "Account ID";
      case "tenantid":
        return "Tenant ID";
      case "clientid":
        return "Client ID";
      case "createassets":
        return "Create Assets";
      case "scanVms":
        return "Scan VMs";
      case "discoverall":
        return "Discover All";
      case "discovergroups":
        return "Discover Groups";
      case "discoverprojects":
        return "Discover Projects";
      case "discoverterraform":
        return "Discover Terraform";
      case "discoverk8smanifests":
        return "Discover Kubernetes Manifests";
      case "baseurl":
        return "Base URL";
      case "enterpriseurl":
        return "Enterprise URL";
      case "datasetid":
        return "Dataset ID";
      case "projectid":
        return "Project ID";
      case "organizationid":
        return "Organization ID";
      case "impersonateduseremail":
        return "Impersonated User Email";
      case "customerid":
        return "Customer ID";
      case "githuborg":
        return "GitHub Organization";
      case "githubtype":
        return "GitHub Integration Type";
      case "reposallowlist":
        return "Allow List";
      case "reposdenylist":
        return "Deny List";
      case "secrettype":
        return "Secret Type";
      default:
        //capitalize first letter
        return config.charAt(0).toUpperCase() + config.slice(1);
    }
  };

  // TODO: Format everything instead of just these
  const formatGroup = [
    "http",
    "https",
    "type",
    "scanVms",
    "discoverAll",
    "discoverGroups",
    "discoverProjects",
    "discoverTerraform",
    "createAssets",
  ];

  const formatScanStartAndFinish = (
    scanJobStarted: string | null | undefined,
    scanJobFinished: string | null | undefined,
  ) => {
    let scanJobStartedCopy = "Never";
    if (scanJobStarted) {
      scanJobStartedCopy = FormatDateShort(scanJobStarted);
    }
    if (scanJobStartedCopy === "Never")
      return `${capitalizeFirstLetter(actionType)} Job not started`;

    let scanJobFinishedCopy = `${actionType} Job has not finished`;
    if (scanJobFinished) {
      scanJobFinishedCopy = FormatDateShort(scanJobFinished);
    }

    if (!scanJobStarted) return scanJobStartedCopy;

    return `${scanJobStartedCopy} - ${scanJobFinishedCopy}`;
  };

  const formatValue = (key: string, value: any) => {
    if (!value && key === "discoverAll") return "false";
    if (!value && key === "scanVms") return "false";

    if (value === true) {
      return "true";
    }
    if (value === false) {
      return "false";
    }

    if (!value) return;

    if (value !== typeof "string") return value;
    switch (value.toLowerCase()) {
      case "repo":
        return "Repository";
      case "org":
        return "Organization";
      default:
        return value;
    }
  };

  if (
    configTypename === "AWSConfigurationOptions" ||
    configTypename === "HostedAwsConfigurationOptions"
  ) {
    const isServerless = configTypename === "AWSConfigurationOptions";
    const isOrganization = isServerless && configOptions.isOrganization;
    const installMethod = isServerless ? "Serverless" : "Hosted";
    const scanScope = isOrganization ? "organization" : "single account";
    const integrationType = `${installMethod}, ${scanScope}`;
    const vpcFlavour = isServerless
      ? configOptions?.scanConfiguration.vpcConfiguration?.vpcFlavour
      : undefined;
    displayedConfigs = {
      "Integration Type": integrationType,
      ...(isServerless
        ? {
            ...(token
              ? {
                  "AWS CloudFormation": (
                    <Link
                      target="_blank"
                      rel="noopener"
                      href={stackUrl(
                        configOptions.cloudFormationTemplateUrl,
                        configOptions.originAWSAccountId,
                        configOptions.region,
                        integration.mrn,
                        token,
                        configOptions.snsEndpoint,
                        configOptions.isOrganization,
                      )}
                      display="inline-flex"
                      gap={1}
                      alignItems="center"
                    >
                      View CloudFormation Configuration{" "}
                      <OpenInNewIcon fontSize="inherit" />
                    </Link>
                  ),
                }
              : {}),
            "Account IDs": `${configOptions.accountIds}`,
            "Full Scan Interval": `${configOptions.scanConfiguration.cronScaninHours} hours`,
            "Discover EC2 Instances": configOptions.scanConfiguration.ec2Scan
              ? "On"
              : "Off",
            "SSM scanning enabled": configOptions.scanConfiguration
              .ec2ScanOptions.ssm
              ? "On"
              : "Off",
            "EC2 Instance Connect scanning enabled": configOptions
              .scanConfiguration.ec2ScanOptions.instanceConnect
              ? "On"
              : "Off",
            "EBS scanning enabled": configOptions.scanConfiguration
              .ec2ScanOptions.ebsVolumeScan
              ? "On"
              : "Off",
            "Discover ECS containers": configOptions.scanConfiguration.ecsScan
              ? "On"
              : "Off",
            "Discover ECR images": configOptions.scanConfiguration.ecrScan
              ? "On"
              : "Off",
            VPC:
              vpcFlavour !== VpcFlavour.DefaultVpc
                ? `Mondoo-created, ${vpcFlavour === VpcFlavour.MondooIgw ? "Internet Gateway" : "NAT Gateway"}`
                : "AWS Default",
          }
        : {}),
      ...(!isServerless
        ? {
            "Authentication method": configOptions.accessKeyId
              ? "Key based"
              : "Role based",
          }
        : {}),
    };
  }

  if (configTypename === "AzureConfigurationOptions") {
    const scanAllSubscriptions = Boolean(
      !configOptions.subscriptionsBlacklist &&
        !configOptions.subscriptionsWhitelist,
    );

    displayedConfigs = {
      ...Object.fromEntries(
        Object.entries(configOptions).filter(
          ([key]) => !key.includes("list") && key !== "__typename",
        ),
      ),
      "Scan all subscriptions": scanAllSubscriptions ? "enabled" : "disabled",
      ...(configOptions.subscriptionsWhitelist
        ? {
            Allowlist: configOptions.subscriptionsWhitelist.join(", "),
          }
        : {}),
      ...(configOptions.subscriptionsBlacklist
        ? {
            Denylist: configOptions.subscriptionsBlacklist.join(", "),
          }
        : {}),
    };
  }

  if (configTypename === "GithubConfigurationOptions") {
    displayedConfigs = {
      "GitHub Integration Type":
        configOptions.githubType === GithubIntegrationType.Org
          ? "Organization"
          : "Repository",
      ...(configOptions.githubType === GithubIntegrationType.Repo
        ? {
            Owner: configOptions.owner,
            Repository: configOptions.repository,
          }
        : {
            Organization: configOptions.githubOrg,
            "Scan all repositories":
              (configOptions.reposAllowList?.length || 0) === 0 &&
              (configOptions.reposDenyList?.length || 0) === 0
                ? "enabled"
                : "disabled",
            ...(configOptions.reposAllowList?.length !== 0 ||
            configOptions.reposDenyList?.length !== 0
              ? {
                  "Allow List":
                    (configOptions.reposAllowList || []).join(", ") || " - ",
                  "Deny List":
                    (configOptions.reposDenyList || []).join(", ") || " - ",
                }
              : {}),
          }),
      discoverTerraform: configOptions.githubDiscoverTerraform
        ? "enabled"
        : "disabled",
      discoverK8sManifests: configOptions.githubDiscoverK8sManifests
        ? "enabled"
        : "disabled",
      enterpriseUrl: configOptions.enterpriseUrl,
    };
  }

  if (configTypename === "JiraConfigurationOptions") {
    displayedConfigs = {
      Host: configOptions.host,
      Email: configOptions.email,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      "Create drift tickets in this integration": configOptions.autoCreateCases
        ? "enabled"
        : "disabled",
      "Drift destination": configOptions.defaultProject || "-",
    };
  }

  if (configTypename === "EmailConfigurationOptions") {
    displayedConfigs = {};

    const defaultDestination = configOptions.recipients.find(
      (r) => r.isDefault,
    )?.email;

    displayedConfigs["Automatically create tickets for cases"] =
      configOptions.autoCreateTickets ? "enabled" : "disabled";

    if (defaultDestination) {
      displayedConfigs["Default recipient"] = defaultDestination;
    }
  }

  if (configTypename === "ZendeskConfigurationOptions") {
    displayedConfigs = {
      Email: configOptions.email,
      "Zendesk subdomain": configOptions.subdomain,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      "Automatically create tickets for cases": configOptions.autoCreateTickets
        ? "enabled"
        : "disabled",
    };
  }

  if (configTypename === "GithubTicketingConfigurationOptions") {
    displayedConfigs = {
      "Enterprise URL": configOptions.enterpriseUrl,
      "Default organization": configOptions.defaultRepoOwner,
      "Default repository": configOptions.defaultRepoName,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      "Create drift tickets in this integration":
        configOptions.autoCreateTickets ? "enabled" : "disabled",
    };
  }

  if (configTypename === "GitlabTicketingConfigurationOptions") {
    displayedConfigs = {
      "Base URL": configOptions.gitlabBaseUrl || "gitlab.com",
      "Default Project": configOptions.gitlabDefaultProject,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      "Create drift tickets in this integration":
        configOptions.autoCreateTickets ? "enabled" : "disabled",
    };
  }

  if (configTypename === "AzureDevopsConfigurationOptions") {
    displayedConfigs = {
      "Organization URL": configOptions.organizationUrl,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      "Create drift tickets in this integration":
        configOptions.autoCreateTickets ? "enabled" : "disabled",
      "Drift destination": configOptions.defaultProjectName || "-",
    };
  }

  if (configTypename === "ShodanConfigurationOptions") {
    displayedConfigs = {
      // split targets into multiple lines
      Targets: configOptions.targets ? (
        <>
          {configOptions.targets.map((target: string) => (
            <Typography variant="inherit">{target.trim()}</Typography>
          ))}
        </>
      ) : (
        ""
      ),
    };
  }

  if (isEmpty(displayedConfigs) && configOptions) {
    displayedConfigs = {
      ...Object.fromEntries(
        Object.entries(configOptions).filter(
          ([key, value]) => key !== "__typename" && value !== null,
        ),
      ),
    };
  }

  return {
    displayedConfigs,
    configFormat: {
      configName: formatConfigName,
      value: formatValue,
      group: formatGroup,
      scanStartAndFinish: formatScanStartAndFinish,
    },
  };
}
